<template>
  <div class="account-settings-info-right">
    <div class="account-settings-info-title">
      <span>{{selectedMenu.meta.title}}</span>
    </div>
    <a-row>
      <h4>Hibernate二级缓存</h4>
      <a-button @click="hibernateModal = true"
                :loading="hibernateStatus">
        <span v-if="!hibernateStatus">清空缓存</span>
        <span v-else>清空中...</span>
      </a-button>
      <a-alert message="执行清空缓存方法后，会清除Hibernate二级缓存（查询、集合、实体缓存）。" banner style="margin-top: 10px;"/>
    </a-row>
    <a-divider type="horizontal"/>
    <a-row>
      <h4>Shiro权限缓存</h4>
      <a-button @click="shiroModal = true"
                :loading="shiroStatus">
        <span v-if="!shiroStatus">清空缓存</span>
        <span v-else>清空中...</span>
      </a-button>
      <a-alert message="执行清空缓存方法后，会清除Shiro权限缓存。" banner style="margin-top: 10px;"/>
    </a-row>
    <a-modal
      title="Hibernate二级缓存"
      v-model="hibernateModal"
      @ok="clearHibernateCache"
      width="500px"
      @cancel="cancelHibernateModal"
    >
      <h1 slot="header" class="p-b-none">清除Hibernate二级缓存确认</h1>
      <p class="font-size-14">此操作将会清除系统中使用的Hibernate二级缓存，执行操作请点击确定按钮</p>
      <p class="text-color-error">备注：执行清空缓存方法后，会清除Hibernate二级缓存（查询、集合、实体缓存）。</p>
    </a-modal>
    <a-modal
      title="Shiro权限缓存"
      v-model="shiroModal"
      @ok="clearShiroCache"
      width="500px"
      @cancel="cancelShiroModal"
    >
      <h1 slot="header" class="p-b-none">清除Shiro权限缓存确认</h1>
      <p class="font-size-14">此操作将会清除系统中使用的Shiro权限缓存，执行操作请点击确定按钮</p>
      <p class="text-color-error">备注：执行清空缓存方法后，会清除Shiro权限缓存。</p>
    </a-modal>
  </div>
</template>

<script>
  import * as permission from '../../../core/permission'
  import SERVICE_URLS from '../../../api/service.url'
  import menuTile from '../../common/mixins/menuTitle'

  export default {
    name: 'indexsystemcache',
    mixins: [menuTile],
    data() {
      return {
        permission: permission,
        hibernateStatus: false,
        hibernateModal: false,
        shiroStatus: false,
        shiroModal: false
      }
    },
    methods: {
      clearHibernateCache() {
        this.$http(this, {
          url: SERVICE_URLS.auth.system.hibernateCache,
          loading: 'hibernateStatus',
          successTipsContent: 'Hibernate二级缓存清除成功',
          success: (data) => {
            this.cancelHibernateModal()
          }
        })
      },
      clearShiroCache() {
        this.$http(this, {
          url: SERVICE_URLS.auth.system.shiroCache,
          loading: 'shiroStatus',
          successTipsContent: 'Shiro权限缓存清除成功',
          success: (data) => {
            this.cancelShiroModal()
          }
        })
      },
      cancelHibernateModal() {
        this.hibernateModal = false
      },
      cancelShiroModal() {
        this.shiroModal = false
      }
    }
  }
</script>